html,
body,
#root {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  background-color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
}

img {
  max-width: 100%;
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 50%;
  }
  #root {
    height: auto;
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}
